// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
	display: inline-block;
	padding: $badge-padding-y $badge-padding-x;
	@include font-size($badge-font-size);
	font-weight: $badge-font-weight;
	line-height: 1;
	color: $badge-color;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	@include border-radius($badge-border-radius);
	@include gradient-bg();

	// Empty badges collapse automatically
	&:empty {
		display: none;
	}
}

// Quick fix for badges in buttons
.btn .badge {
	position: relative;
	top: -1px;
}
